.custom_btn {
	background-color: #ffc500 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border: 0 !important;
	border-radius: 50px !important;
	padding: 1px 12px !important;
	line-height: 35px !important;
	min-width: 100px !important;
	outline: none !important;
}

.custom_btn .btn_icon_container {
	color: #000 !important;
	background: #fff !important;
	margin-left: 15px !important;
	border-radius: 50px !important;
	font-size: 11px !important;
	height: 27px !important;
	width: 27px !important;
	float: right !important;
	text-align: center !important;
	line-height: 29px !important;
	margin-top: 4px !important;
}

.custom_btn .btn_icon_container > MuiSvgIcon-root {
	font-size: 16px !important;
}
.custom_btn .btn_text {
	font-size: 14px !important;
	text-transform: none !important;
	text-align: center !important;
	width: 100% !important;
	margin-right: 5px !important;
	margin-left: 5px !important;
}
