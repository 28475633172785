/* About me section */

.aboutme_text {
	color: darkslategray !important;
	font: smaller !important;
	line-height: 22px !important;
	white-space: pre-line !important;
}

/* End of About me section */

/* Timeline Section */

.resume_timeline .timeline_content .timeline_title {
	font-size: 16px !important;
	font-weight: 500 !important;
}

.resume_timeline .timeline_content .timeline_date {
	color: darkslategray;
}
.resume_timeline .timeline_content .timeline_description {
	color: gray;
	font-size: small;
	margin-top: 6px;
}
/* End of Timeline Section */

/* Services */

.service {
	height: 100%;
	text-align: center;
}

.service .service_title {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 35px !important;
	text-align: center;
}

.service .service_description {
	color: #989898 !important;
	border-color: #ffc500;
	margin-right: 5px !important;
	text-align: center;
}

.service_icon > .MuiSvgIcon-root {
	font-size: 46px !important;
	margin-left: 0 !important;
	font-weight: 300 !important;
	color: darkslategray !important;
}

/* End of Services */

/* Skills */

.skill {
	border-radius: 8px;
	padding: 20px;
	/* background-color: #a46caf !important; */
	text-align: center;

	/* font-size: 14px !important; */

	box-shadow: 0px 0px 48px 0px rgb(4 6 4 / 8%) !important;
	min-height: 186px;
	height: 100%;
}

.skill .skill_title {
	font-size: 15px !important;
	font-weight: 500 !important;
	line-height: 30px !important;
}

.skill .skill_description {
	display: flex;
	align-items: center;
	color: #989898 !important;
	font-weight: 400 !important;
	line-height: 18px !important;
}

.skill .skill_description .timeline_dot {
	color: black !important;
	border-color: #ffc500 !important;
	padding: 2px !important;
	margin-right: 5px !important;
}

/* End of Skill */

/* Start of Contacts Section */
/* 
.contactsInfo_item {
	font-size: 14px !important;
	color: #787878;

	line-height: 34px !important;
}
.contactsInfo_item > span {
	color: black;
	font-weight: 500;
}

.contactInfo_socialsContainer {
	margin-top: 25px;
}

.contactInfo_socials > a > .MuiSvgIcon-root {
	font-size: 16px !important;
	margin-right: 10px;
	color: black;
}

.contact-form {
	/* display: flext; */
/* width: 500px; */
/* } */
/* End of Contacts Section */
