.section {
	padding-left: 30px;
	padding-right: 30px;
}
.pb_45 {
	padding-bottom: 45px !important;
}

.pt_45 {
	padding-top: 45px !important;
}

.section_title {
	position: relative;
	display: inline-block;
	padding: 0 3px;
}

.mb_30 {
	margin-bottom: 30px !important;
}

.section_title .section_title_text {
	font-size: 18px;
	font-weight: 600;
	position: relative;
	margin: 0;
}

.section_title > span {
	position: absolute;
	height: 7px;
	width: 100%;
	bottom: 0;
	opacity: 0.4;
	left: 0;
	background-color: #ffc500;
}

.contact-form {
	/* display: flext !important; */
	margin-left: 12px;
	width: 100%;
}

.contactsInfo_item {
	font-size: 14px !important;
	color: #787878;

	line-height: 34px !important;
}
.contactsInfo_item > span {
	color: black;
	font-weight: 500;
}

.name_Email {
	display: flex !important;
}

.emailContact {
	padding: 0.1px !important;
}

.form_Button {
	margin-top: 8px !important;
	background-color: #ffc500 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	border: 0 !important;
	border-radius: 50px !important;
	padding: 1px 12px !important;
	line-height: 35px !important;
	min-width: 100px !important;
	outline: none !important;
	cursor: pointer;
}

.contactInfo_socialsContainer {
	margin-top: 25px;
}

.contactInfo_socials > a > .MuiSvgIcon-root {
	font-size: 16px !important;
	margin-right: 10px;
	color: black;
}
